import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { dialogActions } from '@dmc-ng/dialog';
import {
  NewVersionDialogComponent,
  NewVersionDialogModel,
} from '@dmc-ng/ui/dialogs';
import { Store } from '@ngrx/store';

@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  private store: Store | null = null;
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.error(error);
      this.openNewVersionBanner();
    }
  }

  openNewVersionBanner(): void {
    if (!this.store) {
      this.store = this.injector.get(Store);
    }
    this.store.dispatch(
      dialogActions.openDialog({
        customComponent: NewVersionDialogComponent,
        config: {
          panelClass: 'dmc-console-dialog',
          disableClose: true,
          autoFocus: false,
        },
        data: {
          clickOnReload: () => {
            window.location.reload();
          },
        } as NewVersionDialogModel,
      }),
    );
  }
}
